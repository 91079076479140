<template>
	<el-dialog
		title="审核结果"
		:visible.sync="visible"
		:modal-append-to-body="true"
		:lock-scroll="false"
		width="60%"
		center
	>
		<div class="content">
			<div class="content-table">
				<table border="1" style="table-layout: fixed">
					<tr>
						<td class="name">项目编号</td>
						<td class="value">
							{{ data.xmbh }}
						</td>
						<td class="name">项目名称</td>
						<td class="value">
							{{ data.xmmc }}
						</td>
					</tr>
					<tr>
						<td class="name">保证金金额</td>
						<td class="value">
							{{ data.jybzj }}
						</td>
						<td class="name">保证金截止时间</td>
						<td class="value">
							{{ data.jjjzsj }}
						</td>
					</tr>
					<tr>
						<td class="name">缴费凭证上传时间</td>
						<td class="value">
							{{ data.pzscsj }}
						</td>
						<td class="name">项目所在区域</td>
						<td class="value">
							{{ data.xmdz }}
						</td>
					</tr>
					<tr>
						<td class="name">竞价人</td>
						<td class="value">
							{{ data.jjLxr }}
						</td>
						<td class="name">竞价人联系方式</td>
						<td class="value">
							{{ data.jjLxfs }}
						</td>
					</tr>
					<tr>
						<td class="name">出让人</td>
						<td class="value">
							{{ data.crLxr }}
						</td>
						<td class="name">出让人联系方式</td>
						<td class="value">
							{{ data.crLxfs }}
						</td>
					</tr>
				</table>
				<div class="img">
					<img :src="data.bzjPz" alt="" />
				</div>
				<div class="sm">
					<span class="label">审核说明</span>
					<el-input type="textarea" v-model="data.auditDesc"></el-input>
				</div>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
      <el-button  style="width: 116px" @click="close">取 消</el-button>
			<el-button type="success" style="width: 150px" v-show="data.auditStatus == '4'" @click="submit">重新上传缴费凭证</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	data() {
		return {
			checked: false,
		}
	},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: function () {
				return {}
			},
		},
	},
	methods: {
		submit() {
			// 提交
			this.$emit('savePzxx', this.data)
		},
		close() {
			// 关闭
			this.$emit('close', false)
		},
	},
}
</script>

<style lang="less" scoped>
.sm {
  display: grid;
  grid-template-columns: 140px 1fr;
}
.rules {
	font-size: 15px;
	border-bottom: 1px solid rgb(92, 91, 91);
}
.agree {
	margin-top: 12px;
}
.img {
  margin: 10px;
  display: flex;
  justify-content: center;
  > img {
    display: inline-block;
    max-width: 600px;
  }
}
table {
	width: 100%;
	text-align: center;
	border: 1px solid #c9c9c9;
	border-bottom: none;
	tr {
		min-height: 40px;
		height: 40px;
		width: 100%;
		td {
      border-top: none;
			border-left: 1px solid #c9c9c9;
			border-right: 1px solid #c9c9c9;
			border-bottom: 1px solid #c9c9c9;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.value {
		border-right: none;
	}
	.name {
		border-right: none;
	}
	.name:first-child {
		border-left: none;
	}
}
</style>
